import React from "react"

import Layout from "../components/layout"
import Grid from "@material-ui/core/Grid"
import SEO from "../components/seo"
import BackgroundImageSection from "../components/BackgroundImageSection"
import Typography from "@material-ui/core/Typography"
import PhoneIcon from "@material-ui/icons/Phone"
import EmailIcon from "@material-ui/icons/Email"
import HomeIcon from "@material-ui/icons/Home"

import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  map: {
    width: "100%",
    height: 600,
  },
}))

const Contact = () => {
  const classes = useStyles()

  return (
    <Layout>
      <SEO title="Kontakt" />
      <BackgroundImageSection
        image="contact"
        heading="Pozovite nas"
        text={
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="overline" display="block" gutterBottom>
                Tu smo da odgovorimo na sva vaša pitanja
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <PhoneIcon fontSize="small" />
            </Grid>
            <Grid item xs={11}>
              <Typography variant="body1">
                <a
                  href="tel:0616618497"
                  style={{ textDecoration: "none", color: "#fff" }}
                >
                  +381 61 661 84 97
                </a>
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <EmailIcon fontSize="small" />
            </Grid>
            <Grid item xs={11}>
              <Typography variant="body1">info@transporter011.com</Typography>
            </Grid>
            <Grid item xs={1}>
              <HomeIcon fontSize="small" />
            </Grid>
            <Grid item xs={11}>
              <Typography variant="body1">
                Nova Mokroluška 13, Voždovac
              </Typography>
            </Grid>
          </Grid>
        }
        buttonText=""
      />
      <iframe
        className={classes.map}
        title="map"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2832.236648712018!2d20.50056001551978!3d44.77598058712052!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x475a71e7ac12f555%3A0x6672030c2f60f576!2sTransporter011!5e0!3m2!1sen!2srs!4v1612870085486!5m2!1sen!2srs"
        frameborder="0"
        allowfullscreen=""
      ></iframe>
    </Layout>
  )
}
export default Contact
